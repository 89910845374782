import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { renderForMobile } from "utils/renderForMobile";
import {
  blocksWithoutTabContent,
  isBanner,
  isNavbar,
  isRecommendation,
  offerConditions,
  recommendation,
  buttonBlock,
  isAnnouncementStrip,
} from "utils/renderBlocks";
import { Container, Grid } from "components/Design/Grid/Grid";
import { RenderBlock } from "components/RenderBlock";
import "components/Layout/style.less";

const RenderOffer = ({ block, blocks, offer }) => {
  switch (block.__typename) {
    case "Cms_ComponentPageOfferConditions":
    case "ComponentPageOfferConditions":
      return (
        <div className="hidden">
          <RenderBlock block={{ ...block, offer }} blocks={blocks} />
        </div>
      );
      break;
    case "Cms_ComponentPageButton":
    case "ComponentPageButton":
      return null;
      break;
    default:
      return (
        <>
          <div className="py-20 lg:py-40">
            <RenderBlock block={block} blocks={blocks} />
          </div>
        </>
      );
      break;
  }
};

export const OfferLayout = ({ offer, blocks }) => {
  const breakpoints = useBreakpoint();
  const isMobile = renderForMobile(breakpoints);

  const recommendations = recommendation(blocks);
  const offerCondition = offerConditions(blocks);
  const button = buttonBlock(blocks);

  return (
    <Container>
      <Grid>
        <div className="lg:col-span-7">
          {blocksWithoutTabContent(blocks).map((b) => {
            const skip =
              isNavbar(b) ||
              isBanner(b) ||
              isRecommendation(b) ||
              isAnnouncementStrip(b);
            if (!skip) {
              return (
                <RenderOffer
                  key={`${b.__typename}:${b.id}`}
                  block={b}
                  blocks={blocks}
                  offer={offer}
                />
              );
            }
          })}
        </div>
        <div className="lg:col-span-5 lg:ml-20 lg:block space-y-24">
          {offerCondition && (
            <RenderBlock
              block={{ ...offerCondition, offer }}
              isMobile={isMobile}
            />
          )}
          {button && <RenderBlock block={button} isMobile={isMobile} />}
        </div>

        {recommendations && (
          <div className="lg:col-span-12">
            <RenderBlock block={recommendations} />
          </div>
        )}
      </Grid>
    </Container>
  );
};
